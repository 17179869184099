export const member = {
   id: -1,
   Face: '',
   kind: 'Member',
   name: '',
   identity: '',
   phone: '',
   email: '',
   password: '',
   // role: { member: CRUD },
   code: '', // SPA | STA | STO
   zone: '', // management
}
export const receptionist = {
   id: -1,
   // Logo: '',
   Card: '',
   Face: '',
   kind: 'Receptionist',
   name: '',
   identity: '',
   telegram: '',
   organization: '',
   // Floors: [],
   phone: '',
   email: '',
   password: '',
   status: '', // Enroll | Canceled | Submit -> Active | Disabled
   // Rooms: [],
}
/*
export const visitor = {
   id: -1,
   Card: '',
   Face: '',
   kind: 'Visitor',
   name: '',
   identity: '',
   phone: '',
   email: '',
}
*/
export const building = {
   id: -1,
   View: '',
   kind: 'Building',
   name: '',
   identity: '',
}
export const floor = {
   id: -1,
   View: '',
   kind: 'Floor',
   name: '',
   identity: '',
   access: '',
   building: '',
}
export const room = {
   id: -1,
   View: '',
   kind: 'Room',
   name: '',
   identity: '',
   access: '',
   building: '',
   floor: '',
}

export const organization = {
   id: -1,
   Logo: '',
   kind: 'Organization',
   name: '',
   identity: '',
   // Floors: [],
}

export const approval = {
   id: -1,
   Card: '',
   Face: '',
   Activities: [],
   kind: 'Approval',
   name: '',
   identity: '',
   activity: '',
   phone: '',
   email: '',
   organization: '',
   floor: '',
   reason: '',
   message: '',
   valid: '',
   until: '',
}
export const invitation = {
   id: -1,
   // Card: '',
   // Face: '',
   Emails: [],
   Activities: [],
   kind: 'Invitation',
   name: '',
   identity: '',
   subject: '',
   message: '',
   activity: '',
   valid: '',
   until: '',
}
export const presence = {
   id: -1,
   Modules: [],
   Outputs: [],
   kind: 'Presence',
   // name: '',
   identity: '',
   qrcode: '',
}
