<template>
   <section id="page-activities" class="mt-8">
      <v-card color="white" elevation="12" class="pa-2 mx-6 mb-6" outlined tile>
         <v-card-title class="align-start">
            <v-sheet color="accent" class="mt-n10" style="z-index: 1;" elevation="6" rounded>
               <v-icon dark size="52" class="pa-6">
                  mdi-history
               </v-icon>
            </v-sheet>
         </v-card-title>
         <v-card flat outlined tile class="pull-top">
            <div class="grey lighten-4 pa-6 pt-5 space-left d-flex space-between">
               <div>
                  <base-title :title="`${activity.kind || 'Historical'} Activities`" class="text-uppercase mt-n1" space="0" />
                  <base-subtitle title="Fill all mandatory fields marks with asterisk *." />
               </div>
               <v-spacer />
               <v-select v-model="activity" :items="activities.filter(j => $hasRole(j.kind.toLowerCase(), 'R'))" label="Activity Type" placeholder="Pilih Aktivitas" outlined hide-details class="mt-n1" style="max-width: 300px;"
                  item-text="kind"
                  :return-object="true" :menu-props="{ bottom: true, offsetY: true }"
                  @change="changeActivity"
                  >
                     <template slot="selection" slot-scope="{ item }">
                        {{ item.text || item.kind }}
                     </template>
                     <template v-slot:item="data">
                        <span :class="{ 'grey--text text--lighten-1': [].includes(data.item.kind) }">
                           {{ data.item.text || data.item.kind }}
                        </span>
                     </template>
               </v-select>
            </div>
            <template v-if="activity.kind && $hasRole(activity.kind.toLowerCase(), 'R')">
               <v-row v-if="isUsed">
                  <v-col cols="12" class="pt-0 d-flex">
                     <v-toolbar flat height="80">
                        <v-spacer />
                     </v-toolbar>
                  </v-col>
               </v-row>
               <v-divider />
               <div v-if="activity.kind === 'Approval'">
                  <n-approval-form :activity="activity" :sequences="sequences" :organizations="organizations" :floors="floors" :reasons="reasons" :searchs="searchRecord" @update="updateRecord" />
                  <n-approval-table :activity="activity" :records="records" :organizations="organizations" :floors="floors" @delete="deleteRecord" />
               </div>
               <!-- <div v-if="activity.kind === 'Invitation'">
                  <n-invitation-form :activity="activity" :sequences="sequences" @update="updateRecord" />
                  <n-invitation-table :activity="activity" :records="records" @delete="deleteRecord" />
               </div> -->
            </template>
         </v-card>
      </v-card>
   </section>
</template>

<script>
import { flatten } from '@/utils/nirtara'
import { approval } from '@/utils/fields.js' // , presence, invitation
export default {
name: 'SectionPageActivity',

components: {
      NApprovalForm: () => import('@/components/activity/NApprovalForm'),
      NApprovalTable: () => import('@/components/activity/NApprovalTable'),
      // NInvitationForm: () => import('@/components/activity/NInvitationForm'),
      // NInvitationTable: () => import('@/components/activity/NInvitationTable'),
   },

   data: () => ({
      isUsed: false,
      activities: [
         { kind: 'Approval', id: -1 },
         // { kind: 'Invitation', id: -1 },
      ],
      activity: {},
      records: [],
      sequences: [],
      organizations: [],
      floors: [],
      reasons: [],
   }),

   created () {
      this.$waitSetting(zone => this.$socket.on(`client/${this.$store.state.zone}/Activity`, this.handleRecord))
   },
   mounted () {
      this.changeActivity(this.$route.params.kind ? this.$route.params : this.activities.find(j => j.kind === 'Approval') || this.activities[0])
   },
   destroyed () {
      this.$socket.off(`client/${this.$store.state.zone}/Activity`, this.handleRecord)
   },
   methods: {
      changeActivity (json) {
         this.activity = Object.assign({}, this.$hasRole(json.kind.toLowerCase(), 'R') ? json : this.activities.find(j => this.$hasRole(j.kind.toLowerCase(), 'R')) || this.activities[0])
         this.selectRecord()
      },
      async selectRecord (kind, skip) {
         !skip && (this.records = [])
         !kind && (kind = this.activity.kind)
         var data = {
            conditions: { kind: kind },
            headers: { command: 'selectActivity' },
            options: { limit: skip ? 600 : 60, skip: skip || 0 },
         }
         // if (kind === 'Registration') {
         //    // data.conditions.zone = '*'
         //    // data.conditions['array.zone'] = this.$store.state.zone // OK for simple
         //    data.conditions.array = { $elemMatch: { zone: this.$store.state.zone } }
         // }
         // console.log(`selectRecord (${kind})`, JSON.parse(JSON.stringify(data)))
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('selectRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Message) this.$store.commit('snackNotification', response.data)
         else {
            // let occupants = []
            switch (kind) {
               // default: break
               case 'Approval':
                  this.records = response.data.map(json => {
                     const activities = json.Activity[0][json.kind].filter(j => j.Activity)
                     json.Activity[0][json.kind] = json.Activity[0][json.kind].filter(j => !j.Activity)
                     return Object.assign({}, approval, flatten(json), { Activities: activities })
                  })
                  // this.organizations = await this.selectParent({ kind: 'Organization' }, { _id: 0, identity: 1, name: '$object.name', Floors: '$array' }, 'Subject')
                  this.selectTenant().then(result => {
                     this.organizations = result.map(record => {
                        const floors = record.Subject[0][record.kind].filter(j => j.Floor)
                        const receptionists = record.Subject[0][record.kind].filter(j => j.Receptionist)
                        record.Subject[0][record.kind] = record.Subject[0][record.kind].filter(j => !(j.Floor || j.Receptionist))
                        return Object.assign({}, flatten(record), { Floors: floors, Receptionists: receptionists })
                     })
                  })
                  this.floors = await this.selectParent({ kind: 'Floor' }, { _id: 0, identity: 1, name: '$object.name' }, 'Location')
                  this.reasons = []
                  // console.log(`selectRecord ( this.floors = ${this.floors.length} )`, JSON.parse(JSON.stringify(this.floors)))
                  break
               case 'Invitation':
                  this.records = response.data.map(json => {
                     const activities = json.Activity[0][json.kind].filter(j => j.Activity)
                     const emails = json.Activity[0][json.kind].filter(j => j.Email)
                     json.Activity[0][json.kind] = json.Activity[0][json.kind].filter(j => !(j.Activity || j.Email))
                     return Object.assign({}, approval, flatten(json), { Activities: activities, Emails: emails })
                  })
                  this.organizations = await this.selectParent({ kind: 'Organization' }, { _id: 0, identity: 1, name: '$object.name', Floors: '$array' }, 'Subject')
                  this.floors = await this.selectParent({ kind: 'Floor' }, { _id: 0, identity: 1, name: '$object.name' }, 'Location')
                  this.reasons = []
                  // console.log(`selectRecord ( this.floors = ${this.floors.length} )`, JSON.parse(JSON.stringify(this.floors)))
                  break
            }
            // console.log(`selectRecord ( ${kind} = ${this.records.length} )`, JSON.parse(JSON.stringify(this.records)))
         }
      },
      selectParent (conditions, projection, type) {
         return new Promise((resolve) => {
            var data = {
               conditions: conditions ?? {},
               projection: projection ?? {},
               headers: { command: `select${type ?? 'Option'}` },
            }
            this.$store.dispatch('mongoose', data)
            .then(response => resolve(response.data.map(json => projection ? json : flatten(json)))).catch(() => resolve([]))
         })
      },
      selectTenant () {
         return new Promise((resolve) => {
            var data = {
               conditions: { kind: 'Organization', zone: this.$store.state.zone },
               headers: { command: 'tenantSubject' },
            }
            this.$store.dispatch('mongoose', data)
            .then(response => resolve(response.data)).catch(() => resolve([]))
         })
      },
      async searchRecord (type, filter, callback) {
         /*
         var data = {
            conditions: filter,
            projection: { identity: 1, name: '$object.name' },
            headers: { command: `select${type}` },
         }
         if (filter.kind === 'Unit') {
            // filter.zone = '*'
            data.projection.zone = 1
            data.projection.building = '$object.building'
            data.projection.Unit = '$object.Unit'
         }
         // console.log('searchRecord (data)', JSON.parse(JSON.stringify(data)))
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('searchRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Message) this.$store.commit('snackNotification', response.data)
         else callback(response.data)
         */
      },
      async updateRecord (item) {
         const array = []
         item.Activities && array.push(...item.Activities)
         item.Emails && array.push(...item.Emails)
         const images = []
         item.Card && images.push({ Card: [item.Card] }) // { Card: item.Card ? [`${item.Card.substr(0, 32)}....`] : [] },
         item.Face && images.push({ Face: [item.Face] }) // { Face: item.Face ? [`${item.Face.substr(0, 32)}....`] : [] },

         var data = {
            update: {
               id: typeof item.id === 'string' ? item.id : undefined,
               site: item.site,
               kind: item.kind,
               zone: item.zone,
               identity: item.identity || '*',
               array: array,
               object: {
                  [item.kind]: images,
                  name: item.name, // visitor | receptionist
                  // below added for approval
                  activity: item.activity,
                  phone: item.phone,
                  email: item.email,
                  organization: item.organization,
                  floor: item.floor,
                  reason: item.reason,
                  message: item.message,
                  valid: item.valid,
                  until: item.until,
                  // below added for invitation
                  // reason: item.reason || item.subject,
                  subject: item.subject,
               },
            },
            headers: { command: item.kind === 'Approval' ? 'updateApproval' : item.kind === 'Invitation' ? 'updateInvitation' : 'updateActivity' },
         }
         console.log('updateRecord ()', '\nitem:', JSON.parse(JSON.stringify(item)), '\ndata:', JSON.parse(JSON.stringify(data)))
         // if (data) return
         const response = await this.$store.dispatch('mongoose', data)
         console.log('updateRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         response.data.Message && this.$store.commit('snackNotification', response.data)
         if (response.data.Activity) {
            this.activity.id = -1
            this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${response.data.kind} updated successfully` }], kind: 'Success' })
         }
      },
      async importRecord (data, callback) {
         // var config = { headers: { command: 'importActivity' } }
         // const response = await this.$store.dispatch('sequelize', { data, config })
         // if (response.data.Message) this.$store.commit('snackNotification', response.data)
         // else callback(Array.isArray(response.data) ? response.data.length : 0)
      },
      async deleteRecord (item) {
         var data = {
            delete: { kind: item.kind, id: item.id || '*' },
            headers: { command: 'deleteActivity' },
         }
         // console.log('deleteRecord (item)', JSON.parse(JSON.stringify(item)))
         // if (data) return
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('deleteRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Message) this.$store.commit('snackNotification', response.data)
         else if (response.data.Activity) this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${response.data.kind} deleted successfully` }], kind: 'Success' })
         else if (Array.isArray(response.data)) this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${response.data.length} ${item.kind}s deleted successfully` }], kind: 'Success' })
      },
      handleRecord (item) {
         console.log('handleRecord (item)', JSON.parse(JSON.stringify(item)))
         const { records, activity } = this
         item.Content.forEach(async record => {
            if (record.kind !== activity.kind) return
            const idx = records.findIndex(j => j.id === record.id)
            idx >= 0 && records.splice(idx, 1)
            records.splice((60 + 600 * 16) - 1, records.length - 9659) // (60 + 600 * 16) = 9660
            if (['Create', 'Update', 'Import'].includes(item.action)) { //, 'Delete'
               const template = record.kind === 'Approval' ? approval : null // record.kind === 'Invitation' ? invitation :
               if (record.kind === 'Approval') {
                  const activities = record.Activity[0][record.kind].filter(j => j.Activity)
                  record.Activity[0][record.kind] = record.Activity[0][record.kind].filter(j => !j.Activity)
                  records.unshift(Object.assign({}, template, flatten(record), { Activities: activities }))
               } else if (record.kind === 'Invitation') {
                  const activities = record.Activity[0][record.kind].filter(j => j.Activity)
                  const emails = record.Activity[0][record.kind].filter(j => j.Email)
                  record.Activity[0][record.kind] = record.Activity[0][record.kind].filter(j => !(j.Activity || j.Email))
                  records.unshift(Object.assign({}, template, flatten(record), { Activities: activities, Emails: emails }))
               } else {
                  records.unshift(Object.assign({}, template, flatten(record)))
               }
            }
         })
      },
   },
}
</script>

<style lang="scss" scoped>
.pull-top {
   margin-top: -92px;
}
.space-left {
   padding-left: 136px !important;
   height: 90px;
}
</style>
